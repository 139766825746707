<template>
  <div>
    <div class="card-tabs mb-3 pb-1 px-3 mx-n3">
      <div
        class="item mr-4"
        :class="{ active: activeTab === item.id }"
        v-for="(item, i) in tabs"
        :key="i"
        @click="activeTab = item.id"
      >
        <div class="icon mr-1">
          <img
            :src="item.icon"
            alt=""
          />
        </div>
        <div
          class="tabs-title"
          v-html="item.title"
        ></div>
      </div>
    </div>

    <div class="list-articles">
      <div
        class="wrap-item"
        v-for="(item, i) in list"
        :key="i"
      >
        <div
          class="first-item mb-3"
          v-if="i === 0"
        >
          <a
            :href="item.url"
            class="article-title"
          >{{ item.title }}</a>
          <div class="article-text">{{ item.summary }}</div>
        </div>
        <div
          class="item"
          v-else
        >
          <v-icon
            class="mr-1"
            small
          >mdi-chevron-right</v-icon>
          <a
            :href="item.url"
            target="_blank"
          > {{ item.title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import articlesData from "./articlesData";
export default {
  data() {
    return {
      activeTab: 50202985,
      tabs: [
        {
          icon: "https://f.start.me/pravda.com.ua",
          title: "Pravda.com.ua - Украинская правда",
          id: 50202985,
        },
        {
          icon: "https://f.start.me/k.img.com.ua",
          title: "Korrespondent (ua) - Новини",
          id: 50202986,
        },
      ],
      items: {},
    };
  },
  computed: {
    list() {
      const list = this.items[this.activeTab];
      if (list) {
        return list.slice(0, 10);
      } else {
        return [];
      }
    },
  },
  created() {
    this.items = articlesData;
  },
};
</script>
